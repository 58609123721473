<template>
    <div class="articles">
        <banner></banner>
        <div class="site-content animate">
            <main class="site-main">
                <article class="hentry">
                    <!-- 文章头部 -->
                    <header class="entry-header">
                        <!-- 标题输出 -->
                        <h1 class="entry-title">迪迦Take Me Higher</h1>
                        <hr>
                        <div class="breadcrumbs">
                            <div id="crumbs">最后更新时间：2021年06月08日</div>
                        </div>
                    </header>
                    <!-- 正文输出 -->
                    <div class="entry-content">
                        <h4 id="引言：">下载链接：</h4>
                        <a href="https://vodkgeyttp8.vod.126.net/cloudmusic/obj/core/1456554573/902ef4a3c56bf28bf468a104f5b127ea.mp4?wsSecret=fc337d16418cd6b9df054ebe0ef5eac6&wsTime=1623159843" target="_blank">
                            <pre><code>https://vodkgeyttp8.vod.126.net/cloudmusic/obj/core/1456554573/902ef4a3c56bf28bf468a104f5b127ea.mp4?wsSecret=fc337d16418cd6b9df054ebe0ef5eac6&wsTime=1623159843</code></pre>
                            </a>
                        <h4>下载说明：</h4>
                        <blockquote>
                            无需复制，直接点击此链接，手机或电脑均可下载，下载到本地之后，把文件名后缀改成.mp3格式即可，建议用电脑下载到本地之后直接修改后缀！！！
                        </blockquote>
                        <h4>或百度网盘直接提取MP3文件(直接点击下方链接即可)：</h4>
                        <blockquote>
                            <a href="https://pan.baidu.com/s/1u0SrthkOApMp7WSOb3qXcA" target="_blank">
                                <pre><code>https://pan.baidu.com/s/1u0SrthkOApMp7WSOb3qXcA</code></pre>
                            </a>
                            提取码：yyds
                        </blockquote>
                    </div>


                    <!-- 文章头部 -->
                    <header class="entry-header">
                        <!-- 标题输出 -->
                        <h1 class="entry-title">感谢你曾来过（原版）</h1>
                        <hr>
                        <div class="breadcrumbs">
                            <div id="crumbs">最后更新时间：2021年10月13日</div>
                        </div>
                    </header>
                    <!-- 正文输出 -->
                    <div class="entry-content">
                        <h4>百度网盘直接提取MP3文件(直接点击下方链接即可)：</h4>
                        <blockquote>
                            <a href="https://pan.baidu.com/s/1e0ccrjF6i-pz2KwvlscC4A " target="_blank">
                                <pre><code>https://pan.baidu.com/s/1e0ccrjF6i-pz2KwvlscC4A</code></pre>
                            </a>
                            提取码：yyds
                        </blockquote>
                    </div>
                    <!--声明-->
                    <div class="open-message">
                        <p style="white-space: normal;">声明：如有疑问，请加QQ：515773148，或者直接点击左下方QQ图标进行添加！！！欢迎来搞，感谢支持</p>
                    </div>
                </article>
            </main>
        </div>
    </div>
</template>

<script>
    import Banner from '@/components/banner'
    import sectionTitle from '@/components/section-title'
    import menuTree from '@/components/menu-tree'
    import './css/articles.less'
    export default {
        name: 'articles',
        data(){
          return{
              showDonate: false,
              menus: []
          }
        },
        components: {
            Banner,
            sectionTitle,
            menuTree
        },
        methods: {
          fetchH(arr,left,right){
              if (right) {
                  return arr.filter(item => item.offsetTop > left && item.offsetTop < right)
              }else {
                  return arr.filter(item => item.offsetTop > left)
              }
          },
          // 生成目录
          createMenus(){
              let arr = []
              for(let i=6;i>0;i--){
                  let temp = []
                  let e = document.querySelector(".entry-content").querySelectorAll(`h${i}`)
                  for (let j=0;j<e.length;j++){
                      let child = this.fetchH(arr,e[j].offsetTop,(j+1 === e.length)?undefined:e[j+1].offsetTop)
                      temp.push({
                          h: i,
                          title: e[j].innerText,
                          id: e[j].id,
                          offsetTop: e[j].offsetTop,
                          child
                      })
                  }
                  if (temp.length){
                      arr = temp
                  }
              }
              this.menus = arr
          }
        },
        mounted(){
            this.createMenus()
        },
        created() {
            
        }
    }
</script>
